@use '../../assets/scss/mixins' as mixin;

$font-size-name-animation-l: 30px;
$font-size-name-animation-s: 4.5vw;
$presentation-animation: reveal 500ms forwards;
$start-delay-animation: 1550ms;

.presentation {
   @include mixin.flex($direction: column, $align: flex-start);
   min-height: calc(100vh + var(--header-height));

   @media screen and (max-height: 550px) {
      margin: 100px 0 175px 0;
   }

   @media screen and (max-width: 400px) {
      min-height: 100vh;
      margin: 85px 0 200px 0;
   }

   @media screen and (max-width: 320px) {
      margin: 0 0 200px 0;
   }

   .hi {
      display: block;
      font-size: 1.15em;
      color: rgb(200, 200, 200);
      margin-bottom: 10px;
      transform: translateY(-5rem);
      opacity: 0;
      animation: $presentation-animation;
      animation-delay: $start-delay-animation;
   }

   .name {
      font-size: 5em;
      font-weight: bold;
      transform: translateY(-5rem);
      opacity: 0;
      animation: $presentation-animation;
      animation-delay: calc($start-delay-animation + 150ms);

      span:first-child {
         margin-right: 20px;
      }

      @media screen and (max-width: 940px) {
         font-size: 4.5em;
      }

      @media screen and (max-width: 880px) {
         font-size: 4em;
      }

      @media screen and (max-width: 500px) {
         font-size: 3.5em;
      }

      @media screen and (max-width: 400px) {
         font-size: 3em;
      }

      @media screen and (max-width: 370px) {
         font-size: 2.5em;
      }

      @media screen and (max-width: 320px) {
         font-size: 2em;
      }
   }

   .caption {
      font-size: 2.15em;
      color: var(--secondary-color);
      transform: translateY(-5rem);
      opacity: 0;
      animation: $presentation-animation;
      animation-delay: calc($start-delay-animation + 300ms);

      @media screen and (max-width: 880px) {
         font-size: 1.75em;
      }

      @media screen and (max-width: 500px) {
         font-size: 1.8em;
      }
   }

   .description {
      width: 70%;
      font-size: 1.2em;
      line-height: 27px;
      color: rgb(200, 200, 200);
      margin: 50px 0 70px 0;
      transform: translateY(-5rem);
      opacity: 0;
      animation: $presentation-animation;
      animation-delay: calc($start-delay-animation + 450ms);

      @media screen and (max-width: 991px) {
         width: 80%;
      }

      @media screen and (max-width: 768px) {
         width: 90%;
      }

      @media screen and (max-width: 600px) {
         width: 95%;
      }

      .blue {
         color: var(--primary-color);
      }
   }

   button {
      padding: 10px 40px;
      color: var(--secondary-color);
      text-transform: uppercase;
      transform: translateY(-5rem);
      opacity: 0;
      animation: $presentation-animation;
      animation-delay: calc($start-delay-animation + 600ms);

      &:hover {
         color: #ffffff;
      }
   }

   @keyframes reveal {
      from {
         opacity: 0;
         transform: translateY(-5rem);
      }
      to {
         opacity: 1;
         transform: translateY(0);
      }
   }
}

// NOT ACTIVE
// Big name animation
.name-container {
   position: relative;
   height: calc($font-size-name-animation-l + 10px);

   span {
      font-size: 1.5em;
   }

   .text {
      font-family: 'Montserrat', sans-serif;
      text-transform: uppercase;
      -webkit-text-stroke: 1px #eee;
      letter-spacing: 5px;
      color: transparent;

      &::before {
         content: attr(data-text);
         position: absolute;
         top: 0;
         left: 0;
         font-size: $font-size-name-animation-l;
         color: var(--secondary-color);
         -webkit-text-stroke: 3px var(--secondary-color);
         width: 0;
         white-space: nowrap;
         overflow: hidden;
         padding-top: 30px;
         border-right: 3px solid var(--secondary-color);
         animation: text-fill-l 2s linear alternate forwards;
      }

      @keyframes text-fill-l {
         20% {
            width: calc($font-size-name-animation-l * 5);
            /* depends from font-size */
         }

         55% {
            width: calc($font-size-name-animation-l * 5);
            /* depends from font-size */
         }

         100% {
            width: 105%;
            border: none;
            /* depends from font-size */
         }
      }
   }

   @media screen and (max-width: 650px) {
      & {
         height: calc($font-size-name-animation-s + 10px);

         .text:before {
            font-size: $font-size-name-animation-s;
            animation: text-fill-s 2s ease-in-out alternate forwards;
         }

         @keyframes text-fill-s {
            20% {
               width: calc($font-size-name-animation-s * 5);
               /* depends from font-size */
            }

            55% {
               width: calc($font-size-name-animation-s * 5);
               /* depends from font-size */
            }

            100% {
               width: 105%;
               border: none;
               /* depends from font-size */
            }
         }
      }
   }
}

// Titles animation
.rotate-words {
   position: relative;
   display: inline;

   span {
      width: max-content;
      position: absolute;
      background-color: var(--secondary-color);
      margin-top: 25px;
      padding: 5px;
      opacity: 0;
      transform-origin: 10% 75%;
      animation: rotateWords 8s var(--d) linear infinite;
   }

   @keyframes rotateWords {
      5% {
         opacity: 1;
      }

      10%,
      20% {
         opacity: 1;
         transform: rotate(3deg);
      }

      15% {
         transform: rotate(-1deg);
      }

      50% {
         opacity: 0;
         transform: translateY(70px) rotate(10deg);
      }
   }
}
