@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import './variables';
@import './commons';

//general
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
   height: 100vh;
   font-family: 'PT Sans', sans-serif;
   font-size: 100%;
   background-color: var(--dark-blue);
   scroll-behavior: smooth;
   scroll-padding-top: 100px;

   #root {
      width: 100%;
      height: 100%;
      color: #ffffff;
   }
}
