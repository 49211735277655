.container {
   position: fixed;
   bottom: 30px;
   right: 15px;
   z-index: 50;
   color: #ffffff;
   background-color: var(--primary-color);
   transform: translateX(20rem);
   animation: slide 400ms ease-in-out forwards;
   animation-delay: 3000ms;

   @keyframes slide {
      from {
         transform: translateX(20rem);
      }
      to {
         transform: translateX(0);
      }
   }

   a {
      display: inline-block;
      padding: 10px 20px;
		
		.icon {
			margin-right: 7.5px;
			transition: 200ms;
		}
	
		&:hover .icon {
			scale: 1.3;
		}
   }


   .x {
      position: absolute;
      top: -8px;
      z-index: 100;
      right: -8px;
      color: #000000;
      background-color: #ffffff;
      padding: 0 5px;
      border-radius: 50%;
		transition: all 200ms;
      cursor: pointer;

		&:hover {
			color: #808080;
		}
   }

   @media screen and (max-width: 650px) {
      bottom: 15px;
   }
}
