@use '../../../assets/scss/mixins' as mixin;

.resume-container {
   @include mixin.flex($direction: column, $gap: 15px);
   text-align: center;
   background-color: #ffffff;
   padding: 30px 100px 20px 100px;
   border-radius: 10px;

   a {
      font-size: 1.1em;
      font-weight: 500;
      color: var(--secondary-color);
      padding: 10px 15px;
      border: 1px solid var(--secondary-color);
      border-radius: 5px;
      transition: all 200ms;

      &:hover,
      &:focus,
      &:active {
         color: #ffffff;
         background-color: var(--secondary-color);
      }

      .icon {
         margin-left: 7.5px;
      }
   }

   .qr {
      height: 230px;

      img {
         display: block;
      }

      span {
         font-size: 1.2em;
         text-transform: uppercase;
         font-weight: bold;
      }
   }
}
