@use '../../../assets/scss/mixins' as mixin;

$gap-nav-elements: 40px;
$transition-time-nav-elements: 200ms;
$font-size-nav-elements: 0.9em;
$menu-slide-time: 300ms;
$nav-el-time: 500ms;

// General
@media screen and (min-width: 769px) {
   .button-close-header {
      display: none;
   }
}

// Header horizontal
header {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 100;
   width: 100%;
   background-color: rgba(10, 10, 65, 0.95);
   padding: 5px 0;
   box-shadow: 0px 4px 20px -5px #000000;

   &.scroll-up-header {
      translate: 0 -100px;
      transition: $menu-slide-time;
   }

   &.scroll-down-header {
      translate: 0;
      transition: $menu-slide-time;
   }

   .header {
      @include mixin.flex($justify: space-between);

      .logo-header {
         transform: translateY(-5rem);
         opacity: 0;
         animation: slide-bottom $nav-el-time forwards;
         animation-delay: 300ms;

         img {
            display: block;
            width: 70px;
         }
      }

      .header-right {
         @include mixin.flex($gap: $gap-nav-elements);

         .list-elements {
            @include mixin.flex($gap: $gap-nav-elements);

            li {
               transform: translateY(-5rem);
               opacity: 0;
               animation: slide-bottom $nav-el-time ease-in-out forwards;
               animation-delay: 650ms;

               &:nth-child(2) {
                  animation-delay: 750ms;
               }

               &:nth-child(3) {
                  animation-delay: 850ms;
               }
            }
         }

         .resume-button {
            transform: translateY(-5rem);
            animation: slide-bottom $nav-el-time ease-in-out forwards;
            animation-delay: 950ms;
         }
      }
   }
}

// Header vertical
.header-portal {
   position: fixed;
   top: 0;
   right: 0;
   z-index: 1000;
   width: 250px;
   height: 100%;
   text-align: center;
   color: #ffffff;
   background-color: rgba(13, 13, 93, 0.975);
   animation: slide-to-left $menu-slide-time ease-out forwards;

   @keyframes slide-to-left {
      from {
         transform: translateX(20rem);
      }
      to {
         transform: translateX(0);
      }
   }

   .button-close-header {
      font-size: 1.5em;
      color: #ffffff;
      transform: translate(-110px, 5px);
      transition: all 100ms;

      &:hover {
         transform: translate(-110px, 5px) scale(1.2);
      }
   }

   li {
      margin-top: 30px;
      transform: translateX(20rem);
      animation: slide-to-left $menu-slide-time ease-out forwards;
      animation-delay: $menu-slide-time - 100ms;

      &:nth-child(2) {
         animation-delay: $menu-slide-time;
      }

      &:nth-child(3) {
         animation-delay: $menu-slide-time + 100ms;
      }
   }

   .resume-button {
      color: var(--secondary-color);
      margin-top: 30px;
      transform: translateX(20rem);
      animation: slide-to-left $menu-slide-time ease-out forwards;
      animation-delay: $menu-slide-time + 200ms;

      &:hover {
         color: #ffffff;
      }
   }

   @media screen and (max-width: 500px) {
      width: 200px;

      .button-close-header {
         transform: translate(-85px, 5px);

         &:hover {
            transform: translate(-85px, 5px) scale(1.2);
         }
      }
   }
}

// Header nav elements
.header-nav-element {
   font-size: $font-size-nav-elements;

   a {
      position: relative;
      text-transform: uppercase;
      padding: 10px 0;
      color: #b4b4b4;
      transition: all $transition-time-nav-elements;

      &::after {
         content: '';
         position: absolute;
         bottom: 0;
         left: 0;
         width: 0;
         height: 2px;
         background-color: var(--secondary-color);
         transition: $transition-time-nav-elements;
      }

      &:hover,
      &:active,
      &:focus,
      &.active {
         color: var(--secondary-color);

         &::after {
            width: 100%;
         }
      }

      &:hover::after,
      &:active::after,
      &:focus::after {
         width: 100%;
      }
   }
}

// Resume
.resume-button {
   font-size: $font-size-nav-elements;
   text-transform: uppercase;
   transition: $transition-time-nav-elements;
}

// Toggler
.toggler {
   font-size: 1.8em;
   color: #ffffff;
   transform: translateY(-5rem);
   opacity: 0;
   transition: all 100ms;
   animation: slide-bottom $nav-el-time forwards;
   animation-delay: 550ms;

   &:hover {
      scale: 1.2;
   }
}

@keyframes slide-bottom {
   from {
      transform: translateY(-5rem);
      opacity: 0;
   }
   to {
      transform: translateY(0);
      opacity: 1;
   }
}
