.message {
   position: fixed;
   bottom: 20px;
   left: -20rem;
   z-index: 200;
   color: #ffffff;
   text-align: center;
   padding: 15px 40px;
   box-shadow: 0px 10px 24px rgba(40, 40, 40, 0.69);

   .x {
      position: absolute;
      top: -8px;
      right: -8px;
      color: #000000;
      background-color: #ffffff;
      padding: 0 5px;
      border-radius: 50%;
      cursor: pointer;

      .icon {
         translate: 1px;
      }
   }
}

.slide-to-right {
   animation: slide-to-right 1000ms forwards;

   @keyframes slide-to-right {
      from {
         left: -20rem;
      }
      to {
         left: 20px;
      }
   }
}
