$dimension: 50px;

.arrow-up-scroll {
   position: fixed;
   bottom: 15px;
   left: 15px;
   z-index: 50;
   width: $dimension;
   height: $dimension;
   font-size: 1.8em;
   color: rgb(255, 255, 255);
   padding: 2px 20px 0 20px;
   border-radius: 5px;
   transition: all 100ms;
   transform: translateX(-5rem);
   animation: slide-to-right 650ms forwards;
   animation-delay: 3200ms;

   @keyframes slide-to-right {
      from {
         transform: translateX(-5rem);
      }
      to {
         transform: translateX(0);
      }
   }

   &:hover,
   &:active,
   &:focus {
      color: var(--secondary-color);
      scale: 1.2;
   }

   .fa-angle-up:first-child {
      transform: translateX(-5.5px);
   }

   .fa-angle-up:last-child {
      transform: translate(-5.5px, -28px);
   }

   @media screen and (max-width: 650px) {
      left: 7.5px;
      width: calc($dimension - 20px);
      height: calc($dimension - 20px);
      font-size: 1.4em;
      padding: 2px 15.5px 0 15.5px;

      .fa-angle-up:first-child {
         transform: translate(-8px, -5px);
      }

      .fa-angle-up:last-child {
         transform: translate(-8px, -27px);
      }
   }
}
