.button {
   color: #ffffff;
   padding: 10px 15px;
   border-radius: 5px;
   border: 1px solid var(--secondary-color);
   transition: all 200ms;

   &:hover {
      background-color: var(--secondary-color);
   }
}
