@use '../../../assets/scss/mixins' as mixin;

.skills {
   @include mixin.flex(
      $justify: flex-start,
      $align: stretch,
      $gap: 15px,
      $wrap: true
   );
   margin-top: 10px;

   li {
      @include mixin.flex($justify: flex-start, $gap: 10px);
      width: calc(25% - 15px);

      @media screen and (max-width: 1050px) {
         width: calc(100% / 3 - 10px);
      }

      @media screen and (max-width: 800px) {
         @include mixin.flex($justify: center, $gap: 10px);
         width: calc(50% - 15px);
      }

      @media screen and (max-width: 768px) {
         width: calc(100% / 3 - 15px);
      }

      @media screen and (max-width: 500px) {
         @include mixin.flex($justify: center, $gap: 10px);
         width: calc(50% - 15px);
      }

      img {
         width: 35px;

         &.javascript {
            width: 32px;
         }

         &.cypress {
            width: initial;
            height: 35px;
         }

         &.php {
            width: 37.5px;
         }

         &.mysql {
            width: 40px;
         }
      }

      span {
         font-size: 0.8em;
      }
   }
}
