@use '../../assets/scss/mixins' as mixin;

.about {
   min-height: calc(100vh - var(--header-height));
   padding: 0 10%;

   @media screen and (max-width: 768px) {
      text-align: center;
   }

   .h1Main {
      font-size: 2.5em;
      margin-bottom: 20px;
   }

   .container {
      @include mixin.flex($wrap: true);

      .content {
         position: relative;
         width: 75%;
         font-size: 1.2em;
         padding-right: 7%;

         .text {
            margin-bottom: 50px;

            & > p {
               margin-bottom: 15px;
            }

            .blue {
               display: inline-block;
               position: relative;
               color: var(--primary-color);

               &:nth-child(2)::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 1px;
                  background-color: var(--primary-color);
                  transition: 200ms;
               }

               &:nth-child(2):hover::after {
                  width: 0;
               }
            }
         }

         button {
            color: var(--secondary-color);
            text-transform: uppercase;
            padding: 10px 35px;
            margin-top: 70px;

            &:hover {
               color: #ffffff;
            }

            .icon {
               margin-left: 12.5px;
            }
         }
      }

      .img-container {
         position: relative;
         width: 25%;
         text-align: center;
         border-radius: 50%;
         translate: 500px;
         opacity: 0;
         transition: all 400ms ease;

         img {
            width: 100%;
         }

         &::after {
            content: '';
            position: absolute;
            top: 15px;
            left: 20px;
            width: 100%;
            height: 100%;
            border: 2px solid var(--secondary-color);
            z-index: -1;
            transition: all 150ms;
         }

         &:hover::after {
            top: 8px;
            left: 13px;
         }
      }

      @media screen and (max-width: 991px) {
         @include mixin.flex($direction: column-reverse, $wrap: true);

         .content {
            width: 100%;
            text-align: justify;
            padding-right: 0;
            padding-left: 0;
         }

         .img-container {
            width: 50%;
            margin-bottom: 70px;
         }
      }

      @media screen and (max-width: 768px) {
         .button {
            @include mixin.flex();
         }
      }
   }

	// Animation fade on scroll
   &.in-page .img-container {
      translate: 0;
      opacity: 1;
   }
}
