footer {
   font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
   font-size: 0.75em;
   text-align: center;
   padding-bottom: 40px;

   .top {
      margin: 25px 0 4px 0;

      select {
         height: 35px;
         color: #b4b4b4;
         background-color: transparent;
         padding-left: 5px;
         margin-bottom: 25px;
         border: 1px solid var(--light-blue);
         border-radius: 5px;

         &:hover {
            color: var(--light-blue);
         }
      }

      .name {
         font-size: 1.1em;
         text-transform: uppercase;
         color: #61dafb;
      }

      .code {
         display: inline-block;
         font-size: 0.85em;
         color: #b5b5b5;
         margin-left: 10px;
         border-bottom: 1px solid var(--light-blue);
         transform: translateY(-2px);

         &:hover {
            color: var(--light-blue);
         }
      }
   }

   select {
      font-size: 0.9rem;
      color: #ffffff;
      background-color: transparent;
      padding: 5px 40px;
      border: 1px solid var(--primary-color);
      border-radius: 5px;
      cursor: pointer;
   }

   .c {
      display: inline-block;
      font-size: 1.2em;
      transform: translateY(2px);
   }
}
