@use '../../../assets/scss/mixins' as mixin;

.container-image {
   position: relative;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   box-shadow: 0px 10px 20px -5px #000000;

   // If video === null
   background-size: cover;
   background-position: top center;
   background-repeat: no-repeat;

   video {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      background: linear-gradient(
         0deg,
         rgba(0, 0, 0, 0.8) 20%,
         rgb(80, 80, 80, 0.2) 100%
      );

      @media screen and (max-width: 768px) {
         background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 30%,
            rgb(80, 80, 80, 0.2) 100%
         );
      }

      @media screen and (max-width: 550px) {
         background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 40%,
            rgb(80, 80, 80, 0.2) 100%
         );
      }
   }

   .content {
      position: absolute;
      left: 50%;
      bottom: 10vh;
      translate: -50%;
      z-index: 10;
      @include mixin.flex($direction: column);
      width: 100%;
      overflow: visible;

      @media screen and (max-width: 768px) {
         bottom: 15vh;
      }

      h1 {
         font-size: 2.5em;
      }

      p {
			font-size: 1.1em;
			color: #c3c3c3;
         margin: 5px 0 30px 0;
      }

      .down {
         font-size: 1.75em;
         background-color: var(--secondary-color);
         padding: 7px 15px;
         border: 1px solid transparent;
         border-radius: 50%;
         transition: all 200ms;
         cursor: pointer;

         &.animation-arrow {
            animation: bounce 4s infinite forwards;
            animation-delay: 5s;

            @keyframes bounce {
               10% {
                  translate: 0;
               }
               20% {
                  translate: 0 20px;
               }
               30% {
                  translate: 0;
               }
            }
         }

         &:hover {
            background-color: transparent;
            color: var(--secondary-color);
            border: 1px solid var(--secondary-color);
            transform: translateY(5px);

            &.animation-arrow {
               animation: none;
            }
         }
      }
   }
}

.bottom-main-project {
   .content {
      @include mixin.flex($direction: column);
      height: calc(100vh - var(--header-height));
   }
}

.icon {
   margin-left: 7px;
}
