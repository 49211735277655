@use '../../../assets/scss/mixins' as mixin;

$start-animation-delay: 2600ms;

.aside-container {
   position: fixed;
   top: 50%;
   right: 0;
   transform: translateY(-50%);
   z-index: 50;
   @include mixin.flex($direction: column, $gap: 20px);
   height: 100%;
   padding-right: 10px;

   .container-item {
      transition: all 100ms;

      &:hover {
         transform: scale(1.2);
      }

      .social-item {
         @include mixin.flex();
         width: 40px;
         height: 40px;
         background-color: #000000;
         border-radius: 50%;
         transform: translateX(5rem);
         animation: slide-left 650ms forwards;
         animation-delay: $start-animation-delay;

         @keyframes slide-left {
            from {
               transform: translateX(5rem);
            }
            to {
               transform: translateX(0);
            }
         }

         &.linkedin {
            animation-delay: calc($start-animation-delay + 100ms);
         }

         &.github {
            animation-delay: calc($start-animation-delay + 200ms);
         }

         &.envelope {
            animation-delay: calc($start-animation-delay + 300ms);
         }

         .socialIcon {
            font-size: 1.55em;
            transform: translate(0.5px, 0.5px);

            path {
               fill: var(--secondary-color);
            }
         }
      }
   }

   @media screen and (max-width: 650px) {
      padding-right: 5px;

      .container-item .social-item {
         width: 32.5px;
         height: 32.5px;

         .socialIcon {
            font-size: 1.35em;
         }
      }
   }
}
