@use '../../assets/scss/mixins' as mixin;

$form-font-size: 1.05em;
$form-elements-padding: 14px;
$translate-fade-animation: 50vw;

.contact {
   @include mixin.flex($direction: column, $justify: center);
   min-height: calc(100vh - var(--header-height));

   @media screen and (min-width: 768px) {
      translate: $translate-fade-animation;
      opacity: 0;
      transition: all 400ms ease;
   }

   &.in-page {
      translate: 0;
      opacity: 1;
   }

   .h1 {
      font-size: 2.5em;
      margin-bottom: 20px;
   }

   .text {
      max-width: 400px;
      text-align: center;
      margin-top: 5px;
   }

   form {
      width: 45%;
      max-width: 700px;
      min-width: 450px;
      margin: 30px 0;

      .input-container {
         position: relative;
         margin-bottom: 25px;

         &:nth-child(3) {
            margin-bottom: 21px;
         }

         .input,
         textarea {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            font-family: inherit;
            font-size: $form-font-size;
            background-color: rgb(210, 210, 210);
            padding: $form-elements-padding;
            border-radius: 5px;

            &::-webkit-input-placeholder {
               color: #717171;
               transition: all 200ms;
            }
         }

         .input {
            height: 50px;

            &:hover::-webkit-input-placeholder,
            &:focus::-webkit-input-placeholder {
               transform: translateY(-45px);
               opacity: 0;
            }
         }

         .label {
            position: absolute;
            left: 0;
            z-index: -1;
            transform: translate(13px, 16px);
            font-size: $form-font-size;
            transition: all 200ms;
         }

         .input:hover + label,
         .input:focus + label {
            transform: translate(13px, -23px);
            color: var(--primary-color);
         }

         .error {
            font-size: 1.1em;
            color: #d00909;
            margin-top: 5px;
         }
      }

      .button-container {
         width: 100%;
         text-align: right;

         button {
            color: #ffffff;
            background-color: var(--primary-color);
            padding: 10px 35px;
            border: none;

            &.onHover:hover {
               background-color: var(--light-blue);
               transform: translateY(2px);
               box-shadow: 0px 13px 11px -2px #000000;
            }
         }
      }

      @media screen and (max-width: 768px) and (min-width: 500px) {
         min-width: 370px;
      }

      @media screen and (max-width: 499px) {
         min-width: 290px;
      }
   }
}
