.container {
   max-width: 1600px;
   margin: auto;
}

.container-sm {
   max-width: 1600px;
   margin: auto;
   padding: 0 45px;
   overflow: hidden;

   @media screen and (max-width: 650px) {
      padding: 0 30px;
   }
}

#home,
.main-project-content {
   padding: 0 120px;
   overflow: hidden;

   @media screen and (max-width: 650px) {
      padding: 0 45px;
   }
}

a {
   color: inherit;
   text-decoration: none;

   &:hover,
   &:active,
   &:focus {
      color: inherit;
   }
}

ul,
li {
   list-style-type: none;
}

button {
   font-size: initial;
   background-color: inherit;
   border: none;
   cursor: pointer;
}

input,
textarea,
select {
   border: none;
   outline: none;
}

select {
   cursor: pointer;
}

section {
   margin-bottom: 200px;
}

.gray-image {
   img {
      transition: filter 200ms;

      @media screen and (min-width: 768px) {
         filter: grayscale(0.9);
      }
   }

   &:hover img {
      filter: grayscale(0);
   }
}

.blue {
   color: var(--primary-color);
}
