.backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   z-index: 2000;
}

.is-black {
   background-color: rgba(0, 0, 0, 0.8);
}
