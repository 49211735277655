.container {
   height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   .image {
      width: 200px;
      margin-bottom: 30px;
   }
}
