@use '../../../assets/scss/mixins' as mixin;

.container {
   position: fixed;
   top: 0;
   left: 0;
	
   .splash {
      @include mixin.flex();
      height: 100vh;
      width: 100vw;
      animation: appear 4800ms forwards;

      span {
         position: relative;
         padding: 5px 0;

         img {
            display: block;
            width: 35px;
         }

         &::before,
         &:after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background-color: var(--secondary-color);
            animation: border-appear 750ms forwards;
            animation-delay: 2500ms;
         }

         &::before {
            top: 0;
         }

         &::after {
            bottom: 0;
         }

         @keyframes border-appear {
            to {
               width: 100%;
            }
         }
      }

      @keyframes appear {
         0% {
            opacity: 0;
            transform: scale(0.5);
         }
         93% {
            opacity: 1;
            transform: scale(2.5);
         }
         100% {
            opacity: 0;
            transform: scale(0.1);
         }
      }
   }
}
