@mixin flex(
   $direction: row,
   $justify: center,
   $align: center,
   $gap: 0,
   $wrap: false
) {
   display: flex;
   flex-direction: $direction;
   justify-content: $justify;
   align-items: $align;
   gap: $gap;
   @if $wrap {
      flex-wrap: wrap;
   }
}
